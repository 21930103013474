import React, { FC, useCallback } from 'react';
import { Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from 'components/UI/Input/input';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import OnlyOwner from 'components/OnlyOwner';
import Help from 'components/Help';
import getErrorText from 'utils/getErrorText';
import { useIsGroup } from 'domains/user/hooks';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import Card from 'components/UI/Card';
import InputAutoSave from 'components/UI/InputAutoSave';
import { isFieldInObject } from 'utils/isFieldInObject';
import LabelField from 'components/UI/LabelField';
import css from './styles.module.scss';
import { CreativeDetail } from '../../../../types';
import { useEditCreativeForm } from './hooks';
import TagAutoSave from '../../../../../../components/UI/TagAutoSave';

type Props = {
  creative: CreativeDetail;
  savedField: Record<string, unknown>;
};

const Creative: FC<Props> = ({ creative, savedField }) => {
  const { t } = useTranslation();
  const { isLocked } = useIsGroup();

  const { advertiser_name, internal_id, title, status, xxhash, type } =
    creative;

  const {
    formik,
    dataTags,
    handlerUpdateTags,
    LTUPastTags,
    handlerRevertTags,
    isSavedTags,
    errorTags,
  } = useEditCreativeForm({
    title,
    status,
    internal_id,
    advertiser_name,
    xxhash,
  });

  const handlerInput = useCallback(() => {
    if (formik.isValid) {
      formik.handleSubmit();
    }
  }, [formik, creative]);

  const { getValueFromSettingForKey } = useUserTheme();
  return (
    <FormikProvider value={formik}>
      <Form>
        <Card className={css.card} isUseAccess>
          <h3 className={css.card__title}>
            {type === 'CLICKUNDER'
              ? t('creative.type.clickunder')
              : t('creative.creative_text')}
            {getValueFromSettingForKey('HideHelp') && (
              <Help
                width={150}
                title={
                  type === 'CLICKUNDER'
                    ? t('creative.type.clickunder')
                    : t('creative.creative_text')
                }
                helpattributes={{
                  link: 'https://terratraf.readme.io/docs/td-creatives-clickander',
                  sizeIcon: 18,
                }}
              />
            )}
          </h3>

          <div className={css.card__form}>
            <OnlyOwner {...{ hide: true }}>
              <Input
                label="ID"
                notField={false}
                disabled
                theme="horizontal"
                inputAttributes={{
                  name: 'internal_id',
                  value: formik.values.internal_id,
                  onChange: formik.handleChange,
                  disabled: true,
                }}
              />
            </OnlyOwner>
            {type !== 'MULTI_FORMAT' && (
              <InputAutoSave
                label={t('creative.create_creative.Advertiser_name')}
                defaultValue={advertiser_name}
                value={formik.values.advertiser_name}
                name="advertiser_name"
                maxLength={80}
                setFieldValue={formik.setFieldValue}
                handlerInputEnter={handlerInput}
                isError={
                  !!formik.touched.advertiser_name &&
                  !!formik.errors.advertiser_name
                }
                errorText={formik.errors.advertiser_name}
                isSaved={isFieldInObject(savedField, 'advertiser_name')}
              />
            )}
            <InputAutoSave
              label={t('creative.create_creative.Name')}
              defaultValue={title}
              value={formik.values.title}
              name="title"
              maxLength={120}
              setFieldValue={formik.setFieldValue}
              handlerInputEnter={handlerInput}
              isError={!!formik.touched.title && !!formik.errors.title}
              errorText={formik.errors.title}
              isSaved={isFieldInObject(savedField, 'title')}
            />
            {status === 'ARCHIVE' ? (
              <LabelField label={t('creative.Status')}>
                <ToggleSwitch
                  activateText={t('clients_page.archive_clients')}
                  deactivateText={t('creative.stat_pause')}
                  onChange={() => {
                    formik.setFieldValue(
                      'status',
                      formik.values.status !== 'STOPPED'
                        ? 'STOPPED'
                        : 'ARCHIVE',
                    );
                    formik.handleSubmit();
                  }}
                  checked={formik.values.status !== 'STOPPED'}
                />
              </LabelField>
            ) : (
              <LabelField label={t('creative.Status')}>
                <ToggleSwitch
                  activateText={t('creative.stat_act')}
                  deactivateText={t('creative.stat_pause')}
                  onChange={() => {
                    formik.setFieldValue(
                      'status',
                      formik.values.status !== 'STOPPED'
                        ? 'STOPPED'
                        : 'LAUNCHED',
                    );
                    formik.handleSubmit();
                  }}
                  checked={formik.values.status !== 'STOPPED'}
                />
              </LabelField>
            )}
            {type !== 'MULTI_FORMAT' && dataTags && (
              <div
                className={cn(css.tagBox, {
                  isLocked,
                })}
              >
                <TagAutoSave
                  tagsData={dataTags}
                  handlerData={handlerUpdateTags}
                  handleRevert={handlerRevertTags}
                  LTUPast={LTUPastTags}
                  isSaved={isSavedTags}
                  isError={errorTags.isError}
                  isErrorTags={errorTags.fields}
                  theme="horizontal"
                  errorText={getErrorText(errorTags.code)}
                  isDisable={isLocked}
                />
              </div>
            )}
          </div>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default Creative;
