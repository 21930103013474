import React, { FC, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormikProvider, Form } from 'formik';
import { FiX } from 'react-icons/fi';
import Card from 'components/UI/Card';
import ElementsNone from 'components/ElementsNone';
import CollapseCard from 'components/UI/CollapseCard';
import cn from 'classnames';
import { useIsGroup } from 'domains/user/hooks';
import i18n from '../../i18n';
import css from './styles.module.scss';
import { usePretargeting } from './hooks/usePretargeting';
import RadioGroup from '../UI/RadioGroup';
import Checkbox from '../UI/Checkbox/checkbox';
import ToggleSwitch from '../UI/ToggleSwitch';
import emptyPretargeting from '../../assets/errors/emptyPretargeting.svg';

type PretargetingProps = {
  /** показывает блок наследования  */
  showExtended?: boolean;
  handlerResetExtent?: () => void;
} & Parameters<typeof usePretargeting>[0];

const Pretargeting: FC<PretargetingProps> = ({
  showExtended = false,
  isExtend = false,
  preTargeting,
  handlerSave,
  handlerResetExtent,
}) => {
  const { isLocked } = useIsGroup();
  const {
    modeFilter,
    setModeFilter,
    values,
    handleSubmit,
    hasValues,
    formikContext,
    setFieldValue,
    mainSearch,
    setMainSearch,
    setBrandSearch,
    brandSearch,
    isDmn_wl_bitbsf,
    setLocalExtend,
    localExtend,
  } = usePretargeting({ preTargeting, handlerSave, isExtend });

  const [tab, setTab] = useState<boolean>(false);

  return (
    <div>
      <FormikProvider value={formikContext}>
        <Form>
          <Card noHover className={css.card} isUseAccess>
            <CollapseCard
              noUpdateHeight
              title={
                <h3>
                  {i18n.t(
                    'campaigns_page.campaign_settings.additional.pre_targ_tit',
                  )}
                </h3>
              }
            >
              {showExtended && (
                <div className={css.extends}>
                  {isExtend ? (
                    <span className={css.title}>
                      {i18n.t(`common.pretargeting.comp_retarget_enable_now`)}
                    </span>
                  ) : (
                    <ToggleSwitch
                      checked={localExtend}
                      onChange={() => {
                        setLocalExtend(true);
                        if (handlerResetExtent) {
                          handlerResetExtent();
                        }
                      }}
                      activateText={i18n.t(`targetings.use_set_creo`)}
                      deactivateText={i18n.t(
                        `common.pretargeting.comp_retarget_enable`,
                      )}
                    />
                  )}
                </div>
              )}
              <Tabs className={css.tabs}>
                <TabList className={css.tablist}>
                  <Tab onClick={() => setTab(!tab)}>
                    <span>
                      {i18n.t(
                        'campaigns_page.campaign_settings.additional.pre_targ_main',
                      )}
                    </span>
                    {values.pretargeting &&
                      hasValues(
                        values.pretargeting.filter(
                          (e) => e.key !== 'dmn_wl_bitbsf',
                        ),
                      ) && <span className="dot" />}
                  </Tab>
                  <Tab onClick={() => setTab(!tab)}>
                    <span>Brand Safety</span>
                    {values.brand_safety &&
                      hasValues(values.brand_safety.disallow_group) && (
                        <span className="dot" />
                      )}
                  </Tab>
                </TabList>
                <TabPanel className={css.panel}>
                  <div className={css.list}>
                    <div className={css.options}>
                      <RadioGroup
                        className={css.radio}
                        name="main"
                        label=""
                        options={[
                          {
                            title: i18n.t('all'),
                            value: false,
                          },
                          {
                            title: i18n.t('selected'),
                            value: true,
                          },
                        ]}
                        setFieldValue={(name: string, value: boolean) => {
                          setModeFilter(value);
                        }}
                        checked={modeFilter}
                      />
                    </div>
                    <div className={css.search}>
                      <input
                        type="text"
                        placeholder={i18n.t(
                          'campaigns_page.campaign_settings.additional.pre_targ_search',
                        )}
                        value={mainSearch}
                        onChange={(event) => setMainSearch(event.target.value)}
                      />
                      {!!mainSearch.length && (
                        <button
                          type="button"
                          onClick={() => setMainSearch('')}
                          disabled={!mainSearch.length}
                        >
                          <FiX />
                        </button>
                      )}
                    </div>
                    <>
                      <div
                        className={cn(css.grid, {
                          _isHide:
                            values.pretargeting &&
                            modeFilter &&
                            !values.pretargeting.some(
                              (e) =>
                                e.key !== 'dmn_wl_bitbsf' && e.checked === true,
                            ),
                        })}
                      >
                        {values.pretargeting &&
                          values.pretargeting.length > 0 &&
                          values.pretargeting
                            .filter((e) => e.key !== 'dmn_wl_bitbsf')
                            .filter((e) => {
                              if (modeFilter) {
                                if (e.checked) {
                                  return e;
                                }
                                return null;
                              }
                              return e;
                            })
                            .filter((e) => {
                              if (
                                e.title &&
                                e.title
                                  .toLowerCase()
                                  .includes(mainSearch.toLowerCase())
                              ) {
                                return e;
                              }

                              if (
                                e.key
                                  .toLowerCase()
                                  .includes(mainSearch.toLowerCase())
                              ) {
                                return e;
                              }

                              return null;
                            })
                            .sort((a, b) => (a.order < b.order ? 1 : -1))
                            .map((e) => (
                              <div className={css.item} key={e.key}>
                                <Checkbox
                                  label={e.title || e.key}
                                  inputAttributes={{
                                    type: 'checkbox',
                                    name: e.key,
                                    checked: e.checked,
                                    disabled: isLocked,
                                    onChange: () => {
                                      const { pretargeting } = values;
                                      const newPretargeting = pretargeting?.map(
                                        (el) =>
                                          el.key === e.key
                                            ? { ...el, checked: !el.checked }
                                            : { ...el },
                                      );
                                      setFieldValue(
                                        'pretargeting',
                                        newPretargeting,
                                      );
                                      setFieldValue('currentValue', {
                                        pretargeting: [
                                          { ...e, checked: !e.checked },
                                        ],
                                      });
                                      handleSubmit();
                                    },
                                  }}
                                />
                              </div>
                            ))}
                      </div>
                      {values.pretargeting &&
                        modeFilter &&
                        !values.pretargeting.some(
                          (e) =>
                            e.key !== 'dmn_wl_bitbsf' && e.checked === true,
                        ) && (
                          <ElementsNone
                            customImg={emptyPretargeting}
                            customTitle={i18n.t(
                              `creative.no_selected_pretarget`,
                            )}
                          />
                        )}
                    </>
                  </div>
                </TabPanel>
                <TabPanel className={css.panel}>
                  {values.pretargeting && (
                    <ToggleSwitch
                      className={css.toggle}
                      activateText={i18n.t(
                        'campaigns_page.campaign_settings.additional.pre_targ_brand_on',
                      )}
                      deactivateText={i18n.t(
                        'campaigns_page.campaign_settings.additional.pre_targ_brand_off',
                      )}
                      checked={isDmn_wl_bitbsf}
                      onChange={() => {
                        const { pretargeting } = values;
                        const newItems = pretargeting?.map((e) => {
                          if (e.key === 'dmn_wl_bitbsf') {
                            setFieldValue('currentValue', {
                              pretargeting: [{ ...e, checked: !e.checked }],
                            });
                            return { ...e, checked: !e.checked };
                          }
                          return { ...e };
                        });
                        setFieldValue('pretargeting', newItems);
                        handleSubmit();
                      }}
                    />
                  )}
                  {values.brand_safety && (
                    <div
                      style={{
                        display: !isDmn_wl_bitbsf ? 'none' : 'block',
                      }}
                    >
                      <RadioGroup
                        className={css.radio}
                        name="brand_safety.0"
                        label={i18n.t(
                          'campaigns_page.campaign_settings.additional.pre_targ_brand_rot',
                        )}
                        options={[
                          {
                            title: i18n.t(
                              'campaigns_page.campaign_settings.additional.pre_targ_brand_rotall',
                            ),
                            value: false,
                          },
                          {
                            title: i18n.t(
                              'campaigns_page.campaign_settings.additional.pre_targ_brand_rotrus',
                            ),
                            value: true,
                          },
                        ]}
                        setFieldValue={(name, value) => {
                          const { brand_safety } = values;
                          if (brand_safety) {
                            setFieldValue('brand_safety.0', {
                              ...brand_safety['0'],
                              checked: value,
                            });
                            setFieldValue('currentValue', {
                              brand_safety: {
                                0: {
                                  ...brand_safety['0'],
                                  checked: value,
                                },
                              },
                            });
                          }
                          handleSubmit();
                        }}
                        checked={values.brand_safety['0']?.checked}
                      />

                      <div className={css.list}>
                        <h4>
                          {i18n.t(
                            'campaigns_page.campaign_settings.additional.pre_targ_brand_rotcat',
                          )}
                        </h4>
                        <div className={css.options}>
                          <RadioGroup
                            className={css.radio}
                            name="brand_safety"
                            label=""
                            options={[
                              {
                                title: i18n.t('all'),
                                value: false,
                              },
                              {
                                title: i18n.t('selected'),
                                value: true,
                              },
                            ]}
                            setFieldValue={(name: string, value: boolean) => {
                              setModeFilter(value);
                            }}
                            checked={modeFilter}
                          />
                        </div>
                        <div className={css.search}>
                          <input
                            type="text"
                            placeholder={i18n.t(
                              'campaigns_page.campaign_settings.additional.pre_targ_search',
                            )}
                            value={brandSearch}
                            onChange={(event) =>
                              setBrandSearch(event.target.value)
                            }
                          />
                          <button
                            type="button"
                            onClick={() => setBrandSearch('')}
                            disabled={!brandSearch.length}
                          >
                            <FiX />
                          </button>
                        </div>
                        <div
                          className={cn(css.grid, {
                            _isHide:
                              values.pretargeting &&
                              modeFilter &&
                              !values.pretargeting.some(
                                (e) =>
                                  e.key !== 'dmn_wl_bitbsf' &&
                                  e.checked === true,
                              ),
                          })}
                        >
                          {values?.brand_safety?.disallow_group &&
                            values?.brand_safety?.disallow_group.length > 0 &&
                            values.brand_safety.disallow_group
                              .filter((e) => {
                                if (
                                  e.title &&
                                  e.title
                                    .toLowerCase()
                                    .includes(brandSearch.toLowerCase())
                                ) {
                                  return e;
                                }

                                if (
                                  e.key
                                    .toLowerCase()
                                    .includes(brandSearch.toLowerCase())
                                ) {
                                  return e;
                                }

                                return null;
                              })
                              .filter((e) => {
                                if (modeFilter) {
                                  if (e.checked) {
                                    return e;
                                  }
                                  return null;
                                }
                                return e;
                              })
                              .sort((a, b) => (a.order < b.order ? 1 : -1))
                              .map((e) => (
                                <div className={css.item} key={e.key}>
                                  <Checkbox
                                    label={e.title}
                                    inputAttributes={{
                                      type: 'checkbox',
                                      name: e.key,
                                      checked: e.checked,
                                      disabled: !isDmn_wl_bitbsf,
                                      onChange: () => {
                                        const { brand_safety } = values;
                                        const newItems =
                                          brand_safety?.disallow_group?.map(
                                            (el) =>
                                              el.key === e.key
                                                ? {
                                                    ...el,
                                                    checked: !el.checked,
                                                  }
                                                : { ...el },
                                          );
                                        setFieldValue(
                                          'brand_safety.disallow_group',
                                          newItems,
                                        );
                                        setFieldValue('currentValue', {
                                          brand_safety: {
                                            disallow_group: [
                                              { ...e, checked: !e.checked },
                                            ],
                                          },
                                        });
                                        handleSubmit();
                                      },
                                    }}
                                  />
                                </div>
                              ))}
                        </div>

                        {values.brand_safety?.disallow_group &&
                          modeFilter &&
                          !values.brand_safety?.disallow_group.some(
                            (e) => e.checked === true,
                          ) && (
                            <ElementsNone
                              customImg={emptyPretargeting}
                              customTitle={i18n.t(
                                `creative.no_selected_pretarget`,
                              )}
                            />
                          )}
                      </div>
                    </div>
                  )}
                </TabPanel>
              </Tabs>
            </CollapseCard>
          </Card>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default Pretargeting;
