import React, {
  ChangeEvent,
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Input from 'components/UI/Input/input';
import { createData } from 'domains/target/model';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import DateRangeFilter from 'components/DateRangeFilter';
import { parsingDate } from 'utils/momentParse';
import get from 'lodash/get';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import SortHeader from 'domains/target/grid/SortHeader';
import i18next from 'i18next';
import { Period, TDates, TypesTable } from 'types/general';
import { numFormat } from 'utils/toFixedNumber';
import { areEqual, FixedSizeList, ListChildComponentProps } from 'react-window';
import { DateRange, ILimits } from 'domains/target/types';
import { useSelector, shallowEqual } from 'react-redux';
import { AppState } from 'redux/rootReducer';
import { FetchedData } from 'redux/fetchedData';
import { useUserTheme } from 'domains/user/model/selectors';
import ListOptions from 'domains/target/listOptions';
import Tooltip from 'components/UI/Tooltip';
import { FiRefreshCcw } from 'react-icons/fi';
import { checkLimitsCellValue } from 'domains/target/model/utils/checkLimitsCellValue';
import Filter from 'components/Target/Item/Filter';
import { limitsTreeTargets } from 'domains/target/model/shared';
import OnlyOwner from 'components/OnlyOwner';
import css from './styles.module.scss';
import gridCss from '../../grid/styles.module.scss';
import Item from './item';
import CrossSVG from './icons/cross.svg';
import ArrowRightSVG from './icons/arrow_right.svg';
import ArrowLeftSVG from './icons/arrow_left.svg';
import {
  GroupTargets,
  RGetStatisticTarget,
  STargets,
  TargetKey,
  TColumns,
  Tdata,
  TPickListOptions,
  Tree,
  TSortType,
  TUserDataTreeResult,
} from '../../model/types';
import Loader from '../../../../components/UI/Loader';
import ModalWithQuestion from '../../../modals/modalWithQuestion';
import { useCampaignInfo } from '../../../campaign/model/selectors';
import statOffSvg from '../../../../assets/stat_off.svg';
import { TreeAndStat } from './hooks/useDataTree';
import { ReactComponent as LimitsSvg } from '../../icons/limits.svg';

type Props = Omit<
  TUserDataTreeResult,
  | 'onChangeLimitsTree'
  | 'removeLimitsFromTree'
  | 'checkAllInArr'
  | 'searchRefresh'
  | 'itemsOnSave'
  | 'isLoadingClearTargetTree'
  | 'setisLoadingClearTargetTree'
> &
  TPickListOptions & {
    period: DateRange;
    initCommonTypePeriod: TDates['type'];
    use_inherit_settingsState: boolean;
    setUseSettings: (p: boolean) => void;
    columns: TColumns;
    total: Tdata | null;
    isEmpty?: boolean;
    type: TargetKey;
    isCreative: boolean;
    isCompain: boolean;
    isLoading: boolean;
    showStatistic: boolean;
    range: Period;
    setRange: React.Dispatch<React.SetStateAction<Period>>;
    toggleShowStatHandler: () => void;
    showHintEvent: () => void;
    fetchStatistics: () => void;
    refreshTargetDataHandler: () => void;
    showLimits: () => void;
    isLimitsView: boolean;
    checkItemLimits: (limitsObj: ILimits | undefined) => boolean;
    onlyChecked: boolean;
    setOnlyChecked: React.Dispatch<React.SetStateAction<boolean>>;
    limitsClickHandler: (
      currentKey: string | number,
      currentTitle: string,
    ) => void;
  };

const style_sortHeader = {
  borderRight: '1px solid #ebebeb',
};

type PStatistics = {
  header: React.MutableRefObject<null>;
  expand: number;
  showHintEvent: () => void;
  onSetExpand: (value: number) => void;
  sortType: TSortType;
  onSetSortType: (v: TSortType) => void;
  total: Tdata | null;
  hScroll: React.RefObject<HTMLDivElement>;
  columns: TColumns;
  colClass: any;
  isEmpty: boolean | undefined;
  type: TargetKey;
  scrollSyncL: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onlyChecked: boolean;
  geoFlattenedData: TreeAndStat[];
  vScroll: React.RefObject<HTMLDivElement>;
  targetGeoColumnLeft: React.RefObject<FixedSizeList<any>>;
  targetGeoColumnData: React.RefObject<FixedSizeList<any>>;
  RowLeft: React.NamedExoticComponent<RenderLeftRowGeo>;
  listRef: React.MutableRefObject<HTMLDivElement | undefined>;
  RenderRow: FC<ListChildComponentProps<TreeAndStat[]>>;
};
export const Statistics: FC<PStatistics> = ({
  header,
  expand,
  showHintEvent,
  onSetExpand,
  sortType,
  onSetSortType,
  total,
  hScroll,
  columns,
  colClass,
  isEmpty,
  type,
  scrollSyncL,
  onlyChecked,
  geoFlattenedData,
  vScroll,
  targetGeoColumnLeft,
  targetGeoColumnData,
  RowLeft,
  listRef,
  RenderRow,
}) => (
  <div className={css.statistics}>
    <div className={gridCss.grid}>
      <div className={gridCss.header} ref={header}>
        <div
          className={gridCss.fixed}
          style={{ width: `${expand}px` }}
          onClick={showHintEvent}
        >
          <SortHeader
            className={gridCss.cell}
            title={
              <>
                <span>{i18next.t('targetings.table.name')}</span>
              </>
            }
            expand={expand}
            setExpand={onSetExpand}
            sortType={sortType}
            setSortType={onSetSortType}
            columnKey="title"
            isShowExpand
          />
          {total && (
            <SortHeader
              className={gridCss.cell}
              style={style_sortHeader}
              title={<span>{i18next.t('targetings.total')}</span>}
              expand={expand}
              setExpand={() => null}
              notSort
              setSortType={() => null}
            />
          )}
        </div>
        <div
          className={gridCss.scroll}
          ref={hScroll}
          style={{
            width: `calc(100% - ${expand}px - 8px)`,
          }}
        >
          <div
            style={{
              justifyContent:
                columns.length <= 2 ? 'flex-start' : 'space-between',
            }}
          >
            {!!columns.length &&
              columns.map((h) => (
                <SortHeader
                  columnKey={h.dataIndex}
                  className={gridCss.cell}
                  title={h.title}
                  expand={expand}
                  setExpand={onSetExpand}
                  setSortType={onSetSortType}
                  sortType={sortType}
                  key={h.dataIndex + h.path}
                  notSort={h.notSort ?? false}
                  additionalClass={colClass[h.dataIndex]}
                />
              ))}
          </div>
          <div>
            {total &&
              !!columns.length &&
              columns.map((val) => (
                <div
                  className={cn(gridCss.cell, colClass[val.dataIndex])}
                  key={val.dataIndex + val.path}
                >
                  {numFormat(i18next.language, total?.[val.dataIndex], '')}
                </div>
              ))}
          </div>
        </div>
      </div>

      <div
        className={cn(
          gridCss.body,
          isEmpty && type !== 'source' ? '_isEmpty' : '',
        )}
        onScroll={scrollSyncL}
        onClick={showHintEvent}
      >
        <FixedSizeList
          className={cn(gridCss.fixed, css.fixed_tree, {
            isEmpty,
          })}
          height={
            onlyChecked && geoFlattenedData.length === 0 && !isEmpty ? 0 : 323
          }
          itemCount={geoFlattenedData.length}
          itemSize={ROW_H}
          itemData={geoFlattenedData}
          layout="vertical"
          width="auto"
          style={{
            width: `${expand}px`,
            paddingTop: `11px`,
            overflowY: 'scroll',
            willChange: 'initial',
          }}
          itemKey={(index) =>
            geoFlattenedData[index].geoname_id + String(index)
          }
          outerRef={vScroll}
          ref={targetGeoColumnLeft}
          onScroll={({ scrollOffset }) =>
            targetGeoColumnData.current?.scrollTo(scrollOffset)
          }
        >
          {RowLeft}
        </FixedSizeList>

        <div
          className={gridCss.content}
          style={{
            width: `calc(100% - ${expand}px)`,
          }}
        >
          <FixedSizeList
            className={gridCss.bidrate}
            height={
              onlyChecked && geoFlattenedData.length === 0 && !isEmpty ? 0 : 323
            }
            itemCount={geoFlattenedData.length}
            itemSize={ROW_H}
            itemData={geoFlattenedData}
            style={fixedSizeListStyle}
            layout="vertical"
            width={columns.length > 2 ? '100%' : colClass.totalWidth}
            itemKey={(index) =>
              geoFlattenedData[index].geoname_id + String(index)
            }
            outerRef={listRef}
            ref={targetGeoColumnData}
            onScroll={({ scrollOffset }) => {
              targetGeoColumnLeft.current?.scrollTo(scrollOffset);
            }}
          >
            {RenderRow}
          </FixedSizeList>

          {columns.length <= 2 && (
            <div className={gridCss.empty}>
              <ReactSVG src={statOffSvg} className="icon" />
              {i18next.t('targetings.geo_id.no_statistics')}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);
const StatisticsMemo = React.memo(Statistics);

const ROW_H = 40;
const fixedSizeListStyle = {
  willChange: 'initial',
  overflow: 'scroll',
};
type RenderLeftRowGeo = {
  index: number;
  style: React.CSSProperties;
  data: any;
};

const HierarchicalActions: FC<Props> = ({
  handleToggle,
  handleChangeCheckbox,
  verifyCheckbox,
  popular,
  handleClearAll,
  updateTree,
  isLoading,
  searchGeo,
  setSearchGeo,
  range,
  setRange,
  initCommonTypePeriod,
  geoSearchCursor,
  setGeoSearchCursor,
  geoRes,
  use_inherit_settingsState,
  columns,
  total,
  isEmpty,
  type,
  targetGeoColumnLeft,
  targetGeoColumnData,
  tree,
  searchAll,
  popularGeoID,
  isCreative,
  isCompain,
  setUseSettings,
  verifyPartiallyChecked,
  showHintEvent,
  showStatistic,
  toggleShowStatHandler,
  refreshTargetDataHandler,
  setIsInvertMode,
  setShowSaveButton,
  exceptions,
  view,
  openModal,
  onGetReport,
  disGetReport,
  showLimits,
  isLimitsView,
  checkItemLimits,
  limitsClickHandler,
  onlyChecked,
  setOnlyChecked,
}) => {
  const { t } = useTranslation();
  const { data: dataCampaign } = useCampaignInfo();
  const [commonTypePeriod, setCommonTypePeriod] = useState<TDates['type']>(
    () => initCommonTypePeriod,
  );

  const statisticTarget = useSelector<
    AppState,
    FetchedData<RGetStatisticTarget>
  >(({ targetReducer }) => targetReducer.statisticTarget?.[type], shallowEqual);

  const statisticTree = statisticTarget && statisticTarget.get('data');

  const [questionModal, setQuestionModal] = useState<boolean>(false);

  const [cardActivator, setCardActivator] = useState<
    MutableRefObject<HTMLDivElement | null> | null | undefined
  >(null);
  const { isThemeFull, isThemeExtended } = useUserTheme();
  const [expand, setExpand] = useState<number>(
    isThemeFull || isThemeExtended ? 320 : 250,
  );
  const [sortType, setSortType] = useState<TSortType>({
    type: TypesTable.DEFAULT,
    column: '',
  });

  const sorting = (data: TreeAndStat[]) => {
    const { type: sortOrder, column } = sortType;
    if (sortOrder === TypesTable.DEFAULT) return;
    if (column === 'title') {
      data.sort((a, b) => {
        const ATitle =
          i18next.language === 'ru'
            ? a.name_ru || a.name_en
            : a.name_en || a.name_ru;
        const BTitle =
          i18next.language === 'ru'
            ? b.name_ru || b.name_en
            : b.name_en || b.name_ru;
        if (ATitle > BTitle) return sortOrder === TypesTable.ASC ? 1 : -1;
        if (ATitle < BTitle) return sortOrder === TypesTable.ASC ? -1 : 1;
        return 0;
      });
    } else {
      const col = columns.find((item) => item.title === column);

      data.sort((a, b) => {
        const AStat = col?.path
          ? checkLimitsCellValue(a.statistic, col.path)
          : a.statistic[col?.dataIndex ?? ''];
        const BStat = col?.path
          ? checkLimitsCellValue(b.statistic, col.path)
          : b.statistic[col?.dataIndex ?? ''];
        if (AStat > BStat) return sortOrder === TypesTable.ASC ? 1 : -1;
        if (AStat < BStat) return sortOrder === TypesTable.ASC ? -1 : 1;
        return 0;
      });
    }
  };

  const addStatToTree = (
    treeItem: Tree,
    statItem: STargets | GroupTargets | undefined,
  ) => {
    const currentStat = statItem?.total || statItem;
    const statWithLimits = treeItem.limits
      ? { ...currentStat, limits: treeItem.limits }
      : currentStat;

    const dataObj = createData({
      k: String(treeItem.geoname_id),
      title: treeItem.name_en,
      bid_rate: treeItem.bid_rate,
      ...statWithLimits,
    });

    return {
      ...treeItem,
      statistic: { ...dataObj },
    };
  };

  const flattenNode = (
    node: TreeAndStat,
    currentNodeStat: STargets | GroupTargets | undefined,
    result: TreeAndStat[],
  ) => {
    if (onlyChecked) {
      const checked = verifyCheckbox(node);
      const partially = verifyPartiallyChecked(node);
      if (!checked && !partially) {
        return;
      }
    }

    result.push(node);

    if (node.isToggle && node.children) {
      const withStat = node.children.map((item) =>
        addStatToTree(item, currentNodeStat?.[item.geoname_id]),
      );
      sorting(withStat);
      // eslint-disable-next-line no-restricted-syntax
      for (const child of withStat) {
        flattenNode(child, currentNodeStat?.[child.geoname_id], result);
      }
    }
  };

  const flattenOpened = (
    treeData: Tree[],
    statisticTreeData: RGetStatisticTarget | null,
  ) => {
    const result: TreeAndStat[] = [];
    const withStat = treeData.map((item) =>
      addStatToTree(item, statisticTreeData?.[item.geoname_id]),
    );
    sorting(withStat);
    // eslint-disable-next-line no-restricted-syntax
    for (const node of withStat) {
      flattenNode(node, statisticTreeData?.[node.geoname_id], result);
    }
    return { result };
  };

  const { result: geoFlattenedData } = useMemo(
    () => flattenOpened(tree, statisticTree),
    [tree, statisticTree, sortType, onlyChecked],
  );

  const [prevSearch, setPrevSearch] = useState({
    search: '',
    cursor: 0,
  });

  const checkLimitsValue = (item: Tdata, key: string) => {
    const value = get(item, key);
    return value === undefined ? '-' : numFormat(i18next.language, value);
  };

  useEffect(() => {
    if (geoSearchCursor.all) {
      if (
        prevSearch.search === searchGeo &&
        geoSearchCursor.index === prevSearch.cursor
      )
        return;
      const index = geoFlattenedData.findIndex(
        ({ geoname_id }) =>
          geoname_id === searchAll[geoSearchCursor.index - 1].geoname_id,
      );
      if (index !== -1) {
        setPrevSearch({
          search: searchGeo,
          cursor: geoSearchCursor.index,
        });
        targetGeoColumnLeft.current?.scrollToItem(index);
        targetGeoColumnData.current?.scrollToItem(index);
      }
    }
  }, [geoSearchCursor.index, geoFlattenedData]);

  useEffect(() => {
    if (type === 'geo_id') {
      if (popularGeoID) {
        const index = geoFlattenedData.findIndex(
          ({ geoname_id }) => geoname_id === popularGeoID,
        );
        targetGeoColumnLeft.current?.scrollToItem(index);
        targetGeoColumnData.current?.scrollToItem(index);
      }
    }
  }, [popularGeoID]);

  const onSetExpand = (value: number) => {
    setExpand(value);
  };

  const onSetSortType = useCallback((v: TSortType) => {
    setSortType(v);
  }, []);

  const rangeProps = useMemo(
    () => ({
      range,
      initialTitleDay: commonTypePeriod,
      dateStart: parsingDate(
        (dataCampaign && dataCampaign.date_start.date) ||
          moment().subtract(365, 'days'),
      ).toDate(),
      dateEnd: parsingDate(
        (dataCampaign && dataCampaign.date_end.date) || new Date(),
      ).toDate(),
    }),
    [range, commonTypePeriod],
  );

  const onSetActive = (
    val: boolean,
    ref: MutableRefObject<HTMLDivElement | null> | undefined,
  ) => {
    if (val) {
      setCardActivator(() => ref);
    } else if (ref === cardActivator) {
      setCardActivator(() => ref);
    }
  };

  const hScroll = useRef<HTMLDivElement>(null);
  const vScroll = useRef<HTMLDivElement>(null);
  const vScrollL = useRef<HTMLDivElement>(null);
  const header = useRef(null);
  const [stop, setStop] = useState<number>(0);
  const listRef = useRef<HTMLDivElement>();

  const scrollSync = useCallback(
    (e: Event) => {
      if (!hScroll.current) return;
      if (e.target instanceof Element) {
        hScroll.current.scrollLeft = e.target.scrollLeft;
        setStop(e.target.scrollTop);
      }
    },
    [hScroll],
  );
  const scrollSyncL = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (!vScrollL.current) return;
      if (e.target instanceof Element && type === 'source' && isEmpty) {
        setStop(e.target.scrollTop);
      }
    },
    [vScrollL, type, isEmpty],
  );

  useEffect(() => {
    listRef.current?.addEventListener('scroll', scrollSync);
    return () => {
      listRef.current?.removeEventListener('scroll', scrollSync);
    };
  }, [listRef.current]);

  const colClass = useMemo(() => {
    const classes: any = {};
    columns.forEach((col) => {
      const differentSizeWithSmallText = 3;
      const sizeTotalData =
        String(Number(total?.[col.dataIndex]).toFixed(2)).length - 3;

      if (
        col.title.length >= sizeTotalData ||
        col.dataIndex === 'bid_rate' ||
        col.dataIndex === 'bid'
      ) {
        classes[col.dataIndex] = `size-${col.title.length}`;
      } else {
        classes[col.dataIndex] = `size-${
          col.title === 'Bid'
            ? sizeTotalData - differentSizeWithSmallText
            : sizeTotalData
        }`;
      }
    });

    /* Рассчет общей ширины, когда нет статистики для области вывода столбцов */
    let totalWidth = 0;
    columns.forEach((item) => {
      const size = Number(classes[item.dataIndex].split('-')[1]);
      totalWidth += size;
    });
    classes.totalWidth = `${columns.length * 40 + totalWidth * 9 + 30}px`;

    return classes;
  }, [total, columns]);

  useEffect(() => {
    if (!vScrollL.current) return;
    if (!vScroll.current) return;

    vScrollL.current.scrollTop = stop;
    vScroll.current.scrollTop = stop;
  }, [stop, vScrollL, vScroll]);

  const RowLeft = memo<RenderLeftRowGeo>(({ index, style, data }) => {
    const node = data[index];
    return (
      <Item
        customStyle={style}
        search={searchGeo}
        type={type}
        item={node}
        handleToggle={handleToggle}
        verifyCheckbox={verifyCheckbox}
        verifyPartiallyChecked={verifyPartiallyChecked}
        handleChangeCheckbox={handleChangeCheckbox}
        editing={use_inherit_settingsState}
        checkItemLimits={checkItemLimits}
        limitsClickHandler={limitsClickHandler}
        index={index}
      />
    );
  }, areEqual);

  const RenderRow = useMemo(
    () =>
      ({
        index,
        style,
        data,
      }: ListChildComponentProps<typeof geoFlattenedData>) => {
        const row = data[index].statistic;

        const isMainCurrent =
          type === 'geo_id' &&
          data[index].geoname_id === data[index].parent.continents;

        return (
          <div
            className={gridCss.column}
            style={{
              ...style,
              justifyContent:
                columns.length <= 2 ? 'flex-start' : 'space-between',
              background: index % 2 === 0 ? '#F9F9F9' : '',
            }}
          >
            {columns.map((cell) => (
              <div
                key={cell.dataIndex + cell.path}
                className={cn(gridCss.cell, colClass[cell.dataIndex], {
                  _isGrey: cell.title.trim().toLocaleLowerCase() === 'bid',
                })}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {cell.cell
                  ? cell.cell(row, isMainCurrent)
                  : // eslint-disable-next-line no-nested-ternary
                  cell.dataIndex === 'key'
                  ? row?.[cell.dataIndex]
                  : cell.path
                  ? checkLimitsValue(row, cell.path)
                  : numFormat(i18next.language, row?.[cell.dataIndex])}
              </div>
            ))}
          </div>
        );
      },
    [columns],
  );

  return (
    <>
      <div className={css.body}>
        <div className={css.title}>
          {type !== 'categories_rtb'
            ? t(`targetings.geo_id.prompt`)
            : t(`targetings.categories.prompt`)}
        </div>

        {!!popular.length && (
          <div className={css.quick}>
            <div className={css.subtitle}>
              {t(`targetings.geo_id.quickChoice`)}
            </div>
            <OnlyOwner>
              <div className={css.items} onClick={showHintEvent}>
                {popular.map(({ title, isChecked, geoname_id }) => (
                  <div
                    key={geoname_id}
                    className={cn(css.item, { active: isChecked })}
                    onClick={
                      use_inherit_settingsState && !isLoading
                        ? () => updateTree(geoname_id, isChecked)
                        : () => null
                    }
                  >
                    {title}
                  </div>
                ))}
              </div>
            </OnlyOwner>
          </div>
        )}
        <div className={css.actions}>
          <div className={css.search}>
            <Input
              classname={css.input}
              inputAttributes={{
                name: 'search',
                placeholder: `${
                  type !== 'categories_rtb'
                    ? t(`targetings.geo_id.search`)
                    : t(`targetings.categories.search`)
                }`,
                onChange: (event: ChangeEvent<HTMLInputElement>) =>
                  setSearchGeo(event.target.value),
                value: searchGeo,
                autoComplete: 'off',
              }}
            />
            {searchGeo !== '' && (
              <div className={css.inputActions}>
                <ReactSVG
                  className={cn(css.arrow, css.arrowLeft, {
                    isActive: geoSearchCursor.index > 1,
                  })}
                  onClick={() => {
                    if (geoSearchCursor.index > 1) {
                      setGeoSearchCursor({
                        index: geoSearchCursor.index - 1,
                        all: geoSearchCursor.all,
                      });
                    }
                  }}
                  src={ArrowLeftSVG}
                />
                <span className={css.values}>
                  {geoSearchCursor.all ? geoSearchCursor.index : 0} /{' '}
                  {geoSearchCursor.all}
                </span>
                <ReactSVG
                  className={cn(css.arrow, css.arrowRight, {
                    isActive: geoSearchCursor.index < geoSearchCursor.all,
                  })}
                  onClick={() => {
                    if (geoSearchCursor.index < geoSearchCursor.all) {
                      setGeoSearchCursor({
                        index: geoSearchCursor.index + 1,
                        all: geoSearchCursor.all,
                      });
                    }
                  }}
                  src={ArrowRightSVG}
                />
                <ReactSVG
                  className={css.cross}
                  onClick={() => {
                    setSearchGeo('');
                  }}
                  src={CrossSVG}
                />
              </div>
            )}
          </div>
          <div className={css.buttons}>
            {type === 'geo_id' && (
              <Filter
                className={cn(css.filter)}
                params={[t('targetings.all'), t('targetings.custom')]}
                setOnlyChecked={setOnlyChecked}
                onlyChecked={onlyChecked}
              />
            )}
            <ToggleSwitch
              className={css.toggle}
              onChange={toggleShowStatHandler}
              checked={showStatistic}
              activateText={`${t(`targetings.geo_id.showStatistics`)}`}
              deactivateText={`${t(`targetings.geo_id.showStatistics`)}`}
            />
            <div
              onClick={refreshTargetDataHandler}
              className={cn(css.update, {
                _isDisabled: isLoading,
              })}
            >
              <Tooltip title={<FiRefreshCcw size={20} />} left>
                {t(`targetings.update_stat`)}
              </Tooltip>
            </div>
            {limitsTreeTargets.includes(type) && (
              <div
                className={cn(css.limits, {
                  isActive: isLimitsView,
                  _isDisabled: isLoading,
                })}
                onClick={showLimits}
              >
                <Tooltip title={<LimitsSvg />} bottom>
                  {t(`targetings.limits.title`)}
                </Tooltip>
              </div>
            )}
            <DateRangeFilter
              className={css['date-range-filter']}
              {...rangeProps}
              onSetDateRange={(dates, typePeriod) => {
                setRange(dates);
                setCommonTypePeriod(typePeriod);
              }}
              onSetActive={onSetActive}
              isTarget
              disabled={isLoading}
              initialTitleDay={initCommonTypePeriod}
            />
            <OnlyOwner>
              <ListOptions
                setIsInvertMode={setIsInvertMode}
                setShowSaveButton={setShowSaveButton}
                isCreative={isCreative}
                isCompain={isCompain}
                setUseSettings={setUseSettings}
                useSettings={use_inherit_settingsState}
                className={css.options}
                exceptions={exceptions}
                disabled={isLoading}
                type={type}
                view={view}
                clearAll={handleClearAll}
                openModal={openModal}
                showHintEvent={showHintEvent}
                onGetReport={onGetReport}
                disGetReport={disGetReport}
                showDownload={false}
              />
            </OnlyOwner>
          </div>
        </div>

        {isLoading ? (
          <div className={css.loader}>
            <Loader />
          </div>
        ) : (
          <StatisticsMemo
            header={header}
            expand={expand}
            showHintEvent={showHintEvent}
            onSetExpand={onSetExpand}
            sortType={sortType}
            onSetSortType={onSetSortType}
            total={total}
            hScroll={hScroll}
            columns={columns}
            colClass={colClass}
            isEmpty={isEmpty}
            type={type}
            scrollSyncL={scrollSyncL}
            onlyChecked={onlyChecked}
            geoFlattenedData={geoFlattenedData}
            vScroll={vScroll}
            targetGeoColumnLeft={targetGeoColumnLeft}
            targetGeoColumnData={targetGeoColumnData}
            RowLeft={RowLeft}
            listRef={listRef}
            RenderRow={RenderRow}
          />
        )}

        {!!geoRes.length && (
          <div className={css.selected}>
            <div className={css.selected_title}>
              {type !== 'categories_rtb'
                ? t(`targetings.geo_id.selected`)
                : t(`targetings.categories.selected`)}
            </div>
            <div className={cn(css.items, css.isEditing)}>
              {!!geoRes.length &&
                geoRes.map(({ name_ru, name_en, childDisable }, index) => (
                  <span key={name_ru}>
                    {i18next.language === 'ru'
                      ? name_ru || name_en
                      : name_en || name_ru}
                    {childDisable?.length
                      ? ` (${t('targetings.geo_id.without')} ${childDisable.map(
                          (v, i) =>
                            `${i + 1 === childDisable.length ? ' ' : ' '}${
                              i18next.language === 'ru'
                                ? v.name_ru ?? v.name_en
                                : v.name_en ?? v.name_ru
                            }`,
                        )})`
                      : ``}
                    {geoRes.length !== index + 1 && `, `}
                  </span>
                ))}
            </div>
          </div>
        )}
      </div>
      {questionModal && isCreative && (
        <ModalWithQuestion
          onClose={() => setQuestionModal(false)}
          onClick={() => {
            setUseSettings(!use_inherit_settingsState);
            if (use_inherit_settingsState) {
              handleClearAll();
            }
            setQuestionModal(false);
          }}
          onClickNegative={() => {
            handleClearAll();
            setQuestionModal(false);
          }}
          subText={t('targetings.use_set_modal_creo')}
          modalName={t('just_words.confirm_action')}
          btnTitle={t('yes')}
          btnCloseTitle={t('no')}
        />
      )}
    </>
  );
};

export default memo(HierarchicalActions);
