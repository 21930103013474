import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Field, FieldProps, Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import Code from 'components/UI/Code';
import EditCard from 'components/UI/EditCard';
import PriceInput from 'components/UI/PriceInput';
import UploadZip from 'domains/uploads/UploadZip';
import UploadImage from 'domains/uploads/UploadImage';
import { getSizes } from 'domains/uploads/helpers';
import Input from 'components/UI/Input/input';
import { useGetFieldConfigValue } from 'domains/creative/creatives/hooks/useGetFieldConfigValue';
import WarningHtml from 'components/WarningHtml';
import { findEridMetaTeg } from 'domains/creative/model/helpers/findEridMetaTeg';
import { useCheckGridEridInfo } from 'domains/creative/creatives/hooks/useCheckGridEridInfo';
import EridWarning from 'components/EridWarning';
import NewSelect from 'components/UI/NewSelect';
import OnlyOwner from 'components/OnlyOwner';
import css from '../styles.module.scss';
import { CreativeDetail, CreativeDetailHTML } from '../../../../../types';
import { useEditCreativeHTML } from './hooks';

type Props = {
  creative: CreativeDetail;
  data: CreativeDetailHTML['data'];
};

const FormType: FC<Props> = ({
  creative: { size, xxhash, config_values },
  data: { html_code },
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const { t } = useTranslation();

  const erid = useGetFieldConfigValue('erid', 'HTML');

  const {
    formik,
    handleFormikSubmit,
    handleFormikReset,
    sizeOption,
    defaultSizeOption,
    checkSizes,
    handlerCopyHtml,
  } = useEditCreativeHTML({
    setEdit,
    setIsLoading,
    payload: {
      html_code,
      size,
      xxhash,
      erid: (erid && config_values[erid.id]) || '',
    },
  });

  const { isDisableSaveButton, setIsDisableSaveButton } = useCheckGridEridInfo({
    eridData: erid,
    eridVal: formik.values.erid,
  });

  useEffect(() => {
    if (formik.values.size) {
      if (defaultSizeOption(formik.values.size)) {
        if (defaultSizeOption(formik.values.size)[0].value === '0x0') {
          setToggle(false);
        } else {
          setToggle(true);
        }
      }
    } else {
      setToggle(true);
    }
  }, [formik.values.size]);

  const showBlock = useSpring<CSSProperties>(
    !toggle
      ? {
          height: 'auto',
          visibility: 'visible',
          opacity: 1,
          transform: 'translateY(0)',
          config: { duration: 120 },
          delay: 0,
          marginBottom: '24px',
        }
      : {
          opacity: 0,
          height: 0,
          visibility: 'hidden',
          transform: 'translateY(-50px)',
          delay: 30,
          marginBottom: '0px',
        },
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          noHover
          noShadow
          hasErrors={
            Object.keys(formik.errors).length > 0 || isDisableSaveButton
          }
          onResetForm={handleFormikReset}
          onSave={handleFormikSubmit}
          setEdit={setEdit}
          hintClassName="erir"
        >
          <div className={css.form}>
            <UploadImage
              keyUploadFile="templateImage"
              setFieldValue={formik.setFieldValue}
              preview={
                (formik.values.imgMask &&
                  formik.values.imgFormats &&
                  getSizes(
                    formik.values.imgMask,
                    formik.values.imgFormats,
                  )) || {
                  file_url: '',
                  client_name: '',
                  image_width: '',
                  image_height: '',
                }
              }
              name="imgMask"
              label={t('creative.creasmall_set.image_word')}
              customLinkTitle={t('creative.creasmall_set.linktoimage_word')}
              hintText={
                <span>
                  {t('creative.creasmall_set.html_format1')}
                  <br />
                  {t('creative.creasmall_set.html_format2')}
                </span>
              }
              hasEdit
              edit={edit}
              minResolution={10}
              file_ext={['.png', '.jpeg', '.jpg']}
              typeSection="templateImage"
              addTabLabel={t(`creative.create_creative.Add_archive`)}
              addTabContent={
                <UploadZip
                  name="zip"
                  hintText={t('creative.create_creative.Supported_format')}
                  label={t('creative.create_creative.File')}
                  sizes={[
                    '160x600',
                    '240x400',
                    '300x250',
                    '300x600',
                    '320x480',
                    '320x50',
                    '336x280',
                    '480x320',
                    '580x400',
                    '728x90',
                    '970x250',
                    '970x90',
                    t('creative.create_creative.Set_sizes'),
                  ]}
                  inputAttributes={{
                    name: 'zip',
                    disabled: !edit,
                  }}
                  setFieldValue={formik.setFieldValue}
                  type="zip"
                />
              }
            />

            <Field
              name="html_code"
              render={({ form, field, meta }: FieldProps) => (
                <OnlyOwner>
                  <Code
                    handlerCopy={handlerCopyHtml}
                    label={t('creative.create_creative.HTML_Code')}
                    form={form}
                    field={field}
                    meta={meta}
                    error={!!formik.errors.html_code}
                    errorText={formik.errors.html_code}
                    maxCount={4096}
                    valueCount={formik.values.html_code?.length}
                    useFullscreen
                    disabled={!edit}
                    readonly={!edit}
                  />
                </OnlyOwner>
              )}
            />
            {!edit &&
              formik.values.erid &&
              !findEridMetaTeg(formik.values.html_code) &&
              !isLoading && (
                <WarningHtml>
                  <>
                    <div>
                      {t(`creative.html.no_tag1`)}
                      <strong>{` <meta name="ad.erid" content="idXXX">.`}</strong>
                      {t(`creative.html.no_tag2`)}
                    </div>
                    <div>{t(`creative.html.error`)}.</div>
                  </>
                </WarningHtml>
              )}

            {edit ? (
              <>
                <Field name="size">
                  {({ form, field }: FieldProps) => (
                    <NewSelect
                      label={t('creative.create_creative.Size_px')}
                      form={form}
                      field={field}
                      selectAttributes={{
                        placeholder: t(
                          'creative.create_creative.Please_select',
                        ),
                        options: sizeOption,
                        value: defaultSizeOption(formik.values.size),
                        onChange: () => {
                          setToggle(checkSizes(formik.values.size));
                        },
                      }}
                      viewStyle="Vertical"
                    />
                  )}
                </Field>
                <animated.div style={showBlock} className={css.sizes}>
                  <PriceInput
                    hasZero
                    classname={css.sizes_col}
                    decimalScale={0}
                    label={`${t('creative.create_creative.Width')}, PX`}
                    placeholder={t('creative.create_creative.Width')}
                    name="width"
                    error={formik.errors.width}
                    errortext={formik.errors.width}
                    value={Number(formik.values.width)}
                    onValueChange={({ floatValue }) => {
                      if (!floatValue) {
                        formik.setFieldValue('width', null);
                        return;
                      }
                      formik.setFieldValue('width', floatValue);
                    }}
                    notField={!edit}
                  />
                  <PriceInput
                    classname={css.sizes_col}
                    hasZero
                    decimalScale={0}
                    label={`${t('creative.create_creative.Height')}, PX`}
                    placeholder={t('creative.create_creative.Height')}
                    name="height"
                    error={formik.errors.height}
                    errortext={formik.errors.height}
                    value={Number(formik.values.height)}
                    onValueChange={({ floatValue }) => {
                      if (!floatValue) {
                        formik.setFieldValue('height', null);
                        return;
                      }
                      formik.setFieldValue('height', floatValue);
                    }}
                    notField={!edit}
                  />
                </animated.div>
              </>
            ) : (
              <div className={css.sizesField}>
                <div className={css.label}>
                  {t('creative.create_creative.Size_px')}
                </div>
                <span>{size || '0x0'}</span>
              </div>
            )}

            {erid && (
              <>
                <Input
                  label={erid.title}
                  notField={edit}
                  showValueCount
                  error={!!formik.errors.erid}
                  errortext={formik.errors.erid}
                  inputAttributes={{
                    name: 'erid',
                    disabled: !edit,
                    maxLength: 80,
                    value: formik.values.erid,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('erid', e.target.value.trim()),
                  }}
                />
                {formik.values.erid && (
                  <EridWarning
                    withAccept={edit}
                    setIsDisableSaveButton={setIsDisableSaveButton}
                    isChangeErid={config_values[erid.id] !== formik.values.erid}
                  />
                )}
              </>
            )}
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default FormType;
